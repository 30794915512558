var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-wrapper"},[_c('BaseNavigation'),_c('DividerNavigation'),_c('div',{staticClass:"p-2 mx-auto"},[_c('app-collapse',{staticClass:"p-0"},[_c('app-collapse-item',{staticClass:"shadow-none p-0",attrs:{"title":"Tambah Pendidikan","is-visible":_vm.isTambahPendidikan},on:{"visible":_vm.changeVisible}},[_c('validation-observer',{ref:"formPendidikan"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Negara Asal Universitas *","label-for":"negara-asal-univ"}},[_c('validation-provider',{attrs:{"name":"Negara Asal Universitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"negara-asal-univ","state":errors.length > 0 ? false:null,"name":"negara-asal-univ","type":"text"},model:{value:(_vm.form.pend_negara),callback:function ($$v) {_vm.$set(_vm.form, "pend_negara", $$v)},expression:"form.pend_negara"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Universitas *","label-for":"nama-univ"}},[_c('validation-provider',{attrs:{"name":"Nama Universitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama-univ","state":errors.length > 0 ? false:null,"name":"nama-univ","type":"text"},model:{value:(_vm.form.pend_nama_univ),callback:function ($$v) {_vm.$set(_vm.form, "pend_nama_univ", $$v)},expression:"form.pend_nama_univ"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jenjang Pendidikan *","label-for":"jenjang-pendidikan"}},[_c('validation-provider',{attrs:{"name":"Jenjang Pendidikan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.optionJenjangPendidikan},model:{value:(_vm.form.pendlevel_id),callback:function ($$v) {_vm.$set(_vm.form, "pendlevel_id", $$v)},expression:"form.pendlevel_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Studi *","label-for":"nama-studi"}},[_c('validation-provider',{attrs:{"name":"Nama Studi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama-studi","state":errors.length > 0 ? false:null,"name":"nama-studi","type":"text"},model:{value:(_vm.form.pend_nama_studi),callback:function ($$v) {_vm.$set(_vm.form, "pend_nama_studi", $$v)},expression:"form.pend_nama_studi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tahun Masuk *","label-for":"tahun-masuk"}},[_c('validation-provider',{attrs:{"name":"Tahun Masuk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tahun-masuk","state":errors.length > 0 ? false:null,"name":"tahun-masuk","type":"number"},model:{value:(_vm.form.pend_thn_masuk),callback:function ($$v) {_vm.$set(_vm.form, "pend_thn_masuk", $$v)},expression:"form.pend_thn_masuk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tahun Selesai *","label-for":"tahun-selesai"}},[_c('validation-provider',{attrs:{"name":"Tahun Selesai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tahun-selesai","state":errors.length > 0 ? false:null,"name":"tahun-selesai","type":"number"},model:{value:(_vm.form.pend_thn_keluar),callback:function ($$v) {_vm.$set(_vm.form, "pend_thn_keluar", $$v)},expression:"form.pend_thn_keluar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nomor Ijazah *","label-for":"nomor-ijazah"}},[_c('validation-provider',{attrs:{"name":"Nomor Ijazah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nomor-ijazah","state":errors.length > 0 ? false:null,"name":"nomor-ijazah","type":"number"},model:{value:(_vm.form.pend_nomor_ijazah),callback:function ($$v) {_vm.$set(_vm.form, "pend_nomor_ijazah", $$v)},expression:"form.pend_nomor_ijazah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"File Ijazah","label-for":"file"}},[_c('validation-provider',{attrs:{"name":"File Ijazah"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"file","state":errors.length > 0 ? false:null,"name":"file","accept":"image/*"},on:{"change":function($event){return _vm.onChangeFileKompetensi($event)}},model:{value:(_vm.tempFileKompetensi),callback:function ($$v) {_vm.tempFileKompetensi=$$v},expression:"tempFileKompetensi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.pend_ijazah_file)?_c('b-img',{staticClass:"mt-1",staticStyle:{"max-height":"250px"},attrs:{"fluid":"","center":"","src":_vm.form.pend_ijazah_file,"alt":"fileKompetensi"}}):_vm._e()],1),_c('b-button',{attrs:{"type":"submit","variant":"outline-danger","block":""},on:{"click":_vm.submit}},[_vm._v(" Simpan ")])],1)],1)],1)],1),_c('hr'),_c('div',{staticClass:"font-weight-bold mb-1",staticStyle:{"font-size":"16px"}},[_vm._v(" Daftar Pendidikan ")]),_vm._l((_vm.pendidikan.data),function(item){return _c('b-card',{key:item.id,staticClass:"shadow-none border p-1 mb-1",attrs:{"no-body":""}},[_c('table',[_c('tbody',[_c('tr',[_c('td',[_vm._v("Negara")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(item.pend_negara)+" ")])]),_c('tr',[_c('td',[_vm._v("Nama Universitas")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(item.pend_nama_univ)+" ")])]),_c('tr',[_c('td',[_vm._v("Studi")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(item.pend_nama_studi)+" ")])]),_c('tr',[_c('td',[_vm._v("Nomor Ijazah")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(item.pend_nomor_ijazah)+" ")])]),_c('tr',[_c('td',[_vm._v("Tahun")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" : "+_vm._s(item.pend_thn_masuk)+" - "+_vm._s(item.pend_thn_keluar)+" ")])])])]),_c('b-img',{staticClass:"mt-1",staticStyle:{"max-height":"250px"},attrs:{"src":_vm.photoIjazah(item),"fluid":"","center":""}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }